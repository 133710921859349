import {
  DashboardIcon,
  OrderOverviewIcon,
  InventoryIcon,
  DocumentsIcon,
  IconI
} from '../icons/navigation-icons'

export type NavItems = {
  linkTo: string
  icon: React.ComponentType<IconI>
}

export const employeeNavItems: NavItems[] = [
  { linkTo: 'dashboard', icon: DashboardIcon },
  { linkTo: 'order-overview', icon: OrderOverviewIcon },
  { linkTo: 'inventory', icon: InventoryIcon },
  { linkTo: 'documents', icon: DocumentsIcon }
]

export const adminNavItems: NavItems[] = [
  { linkTo: 'users', icon: DashboardIcon },
  { linkTo: 'organizations', icon: DashboardIcon }
]
