import { IonList, IonLabel, IonIcon } from '@ionic/react'
import { SubTitle } from '../../ui/Title'
import { useTranslation } from 'react-i18next'
import { closeOutline, checkmarkOutline } from 'ionicons/icons'
import {
  testDigit,
  testLowercase,
  testMinLength,
  testSymbol,
  testUppercase
} from '../../utils/regex'

export default function PasswordRequirements({
  password
}: {
  password: string
}) {
  const hasDigit = testDigit(password)
  const hasLowercase = testLowercase(password)
  const hasSymbol = testSymbol(password)
  const hasUppercase = testUppercase(password)
  const hasMinLength = testMinLength(password)

  const { t } = useTranslation()
  return (
    <IonList
      className="ion-no-margin ion-no-padding"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 20
      }}
    >
      <SubTitle style={{ fontSize: 16 }}>{t('password-requirements.title')}</SubTitle>
      <div>
        {password ? (
          <IonIcon
            style={{
              color: hasMinLength ? '#7aeb7a' : 'red',
              verticalAlign: 'middle'
            }}
            icon={hasMinLength ? checkmarkOutline : closeOutline}
          />
        ) : null}
        <IonLabel
          style={{
            fontSize: 13,
            color: password ? (hasMinLength ? '#7aeb7a' : 'red') : 'black',
            marginLeft: 5
          }}
        >
          {t('password-requirements.min-length')}
        </IonLabel>
      </div>
      <div>
        {password ? (
          <IonIcon
            style={{
              color: hasUppercase ? '#7aeb7a' : 'red',
              verticalAlign: 'middle'
            }}
            icon={hasUppercase ? checkmarkOutline : closeOutline}
          />
        ) : null}
        <IonLabel
          style={{
            fontSize: 13,
            color: password ? (hasUppercase ? '#7aeb7a' : 'red') : 'black',
            marginLeft: 5
          }}
        >
          {t('password-requirements.uppercase-letter')}
        </IonLabel>
      </div>
      <div>
        {password ? (
          <IonIcon
            style={{
              color: hasLowercase ? '#7aeb7a' : 'red',
              verticalAlign: 'middle'
            }}
            icon={hasLowercase ? checkmarkOutline : closeOutline}
          />
        ) : null}
        <IonLabel
          style={{
            fontSize: 13,
            color: password ? (hasLowercase ? '#7aeb7a' : 'red') : 'black',
            marginLeft: 5
          }}
        >
          {t('password-requirements.lowercase-letter')}
        </IonLabel>
      </div>
      <div>
        {password ? (
          <IonIcon
            style={{
              color: hasSymbol ? '#7aeb7a' : 'red',
              verticalAlign: 'middle'
            }}
            icon={hasSymbol ? checkmarkOutline : closeOutline}
          />
        ) : null}
        <IonLabel
          style={{
            fontSize: 13,
            color: password ? (hasSymbol ? '#7aeb7a' : 'red') : 'black',
            marginLeft: 5
          }}
        >
          {t('password-requirements.special-character')}
        </IonLabel>
      </div>
      <div>
        {password ? (
          <IonIcon
            style={{
              color: hasDigit ? '#7aeb7a' : 'red',
              verticalAlign: 'middle'
            }}
            icon={hasDigit ? checkmarkOutline : closeOutline}
          />
        ) : null}
        <IonLabel
          style={{
            fontSize: 13,
            color: password ? (hasDigit ? '#7aeb7a' : 'red') : 'black',
            marginLeft: 5
          }}
        >
          {t('password-requirements.number')}
        </IonLabel>
      </div>
    </IonList>
  )
}
