import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react'

type MenuContextType = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const MenuContext = createContext({} as MenuContextType)

export function useMenuContext() {
  return useContext(MenuContext)
}

export function MenuProvider(props: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpen
      }}
    >
      {props.children}
    </MenuContext.Provider>
  )
}
