import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Users } from '../../api'
import { UserAddInputDTO } from '../../types/users'
import useDangerToast from '../toasts/useDangerToast'
import { useTranslation } from 'react-i18next'

export default function useCreateUser() {
  const presentToast = useDangerToast()
  const { t } = useTranslation()
  const errorMessage: string = t('common.fetchErrorMessage')

  const queryClient = useQueryClient()
  return useMutation((user: UserAddInputDTO) => Users.createUser(user), {
    onSuccess: () => {
      queryClient.invalidateQueries(['users'])
    },
    onError: error => {
      console.error('Error:', error)
      presentToast(errorMessage, 5000)
    }
  })
}
