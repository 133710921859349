import { useMutation } from '@tanstack/react-query'
import { Auth } from '../../api'
import { ChangePasswordData } from '../../types/auth'
import useDangerToast from '../toasts/useDangerToast'
import { useTranslation } from 'react-i18next'

export default function useChangePassword() {
  const presentToast = useDangerToast()
  const { t } = useTranslation()
  const errorMessage: string = t('common.fetchErrorMessage')

  return useMutation((data: ChangePasswordData) => Auth.changePassword(data), {
    onSuccess: () => {},
    onError: error => {
      console.error('Error:', error)
      presentToast(errorMessage, 5000)
    }
  })
}
