import styled from 'styled-components'
import { GermanBanner, EnglishBanner } from '../../icons'
import { useTranslation } from 'react-i18next'
const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Space = styled.div`
  margin: 10px;
`

export default function TranslationBanners() {
  const { i18n } = useTranslation()

  function handleChangeLanguage(language: 'en-US' | 'de') {
    i18n.changeLanguage(language)
    localStorage.setItem('current-language', language)
  }
  return (
    <Flex>
      <EnglishBanner onClick={() => handleChangeLanguage('en-US')} />
      <Space />
      <GermanBanner onClick={() => handleChangeLanguage('de')} />
    </Flex>
  )
}
