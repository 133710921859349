import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSegment,
  IonSegmentButton
} from '@ionic/react'
import { Input } from '../../../ui/common/Input'
import useCreateUser from '../../../hooks/mutations/useCreateUser'
import useUpdateUser from '../../../hooks/mutations/useUpdateUser'
import useUser from '../../../hooks/queries/useUser'
import useDangerToast from '../../../hooks/toasts/useDangerToast'
import useSuccessToast from '../../../hooks/toasts/useSuccessToast'
import useWarningToast from '../../../hooks/toasts/useWarningToast'
import { Company } from '../../../types/company'
import {
  User,
  UserAddInputDTO,
  UserOutputDTO,
  UserRole,
  UserUpdateInputDTO
} from '../../../types/users'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../../context/AuthContext'
import { useQueryClient } from '@tanstack/react-query'

export enum TitleEnum {
  CreateUser = 'create',
  EditUser = 'edit',
  EditProfile = 'editProfile'
}

export type DataDismiss = Partial<User> & {
  companyId?: number
}

const Label = styled(IonLabel)`
  font-family: Poppins !important;
`

const Option = styled(IonSelectOption)`
  font-family: Poppins !important;
`

const Select = styled(IonSelect)`
  font-family: Poppins !important;
`

const StyledIonTitle = styled(IonTitle)`
  font-size: 30px;
  padding: 20px 0 20px 20px;
`

const StyledIonList = styled(IonList)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

type CreateOrEditUserModalContentProps = {
  companies?: Company[]
  title: TitleEnum
  initialUser?: UserOutputDTO
  user?: UserUpdateInputDTO
  onDismiss: (data?: Partial<DataDismiss> | null, role?: string) => void
}

function CreateOrEditUserModalContent(props: CreateOrEditUserModalContentProps) {
  const [option, setOption] = useState<Company>()
  const [searchQuery, setSearchQuery] = useState('')
  const client = useQueryClient()

  const [username, setUserName] = useState<string>()
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [role, setRole] = useState<string>(UserRole.Employee)

  const [preferredLanguage, setPreferredLanguage] = useState<'en' | 'de'>()

  const { t } = useTranslation()
  const title = t(`users-page.title.${props.title}`)
  const searchPlaceholder = t('users-page.label.search-company')
  const labelCompanyName = t('users-page.label.company-name')
  const labelCancel = t('common.cancel')
  const labelDone = t('common.done')
  const labelUserName = t('users-page.label.user-name')
  const labelEmail = t('users-page.label.email')
  const labelRole = t('users-page.label.role')
  const labelRoleOptionAdmin = t('users-page.label.role-options.admin')
  const labelRoleOptionEmployee = t('users-page.label.role-options.customer')
  const labelFirstName = t('users-page.label.first-name')
  const labelLastName = t('users-page.label.last-name')
  const labelLanguage = t('users-page.label.language')
  const labelLanguageOptionGerman = t('users-page.label.language-options.german')
  const labelLanguageOptionEnglish = t('users-page.label.language-options.english')
  const labelOrganisation = t('navigation.organizations')
  const labelFailToUpdateProfile = t('toast.fail-update-profile')
  const labelSuccessToUpdateProfile = t('toast.success-update-profile')
  const labelSomethingWentWrong = t('toast.something-went-wrong')
  const labelCreateUserSuccess = t('toast.create-user-success')

  const { currentUser } = useAuthContext()
  const userId: number = currentUser?.nameid ? +currentUser.nameid : 0
  const loggedUser = useUser(userId)

  const createUser = useCreateUser()
  const updateUser = useUpdateUser()
  const successToast = useSuccessToast()
  const dangerToast = useDangerToast()
  const warningToast = useWarningToast()

  const isCreateUserModal = props.title === TitleEnum.CreateUser
  const isEditProfileModal =
    props.title !== TitleEnum.EditUser && props.title !== TitleEnum.CreateUser

  const isDisabled = (): boolean => {
    if (props.title === TitleEnum.EditUser) {
      if (!firstName || !lastName || !email) {
        return true
      }
    }

    if (props.title === TitleEnum.CreateUser) {
      if (
        !username ||
        !firstName ||
        !lastName ||
        !email ||
        !role ||
        !preferredLanguage ||
        (role === UserRole.Admin ? false : option?.id != null ? false : true)
      ) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    if (props.title === TitleEnum.EditProfile) {
      setFirstName(loggedUser.data?.firstName)
      setLastName(loggedUser.data?.lastName)
      setEmail(loggedUser.data?.email)
    }
  }, [])

  useEffect(() => {
    if (props.initialUser) {
      setUserName(props.initialUser.username)
      setFirstName(props.initialUser.firstName)
      setLastName(props.initialUser.lastName)
      setEmail(props.initialUser.email)
      setRole(props.initialUser.role!)
    }
  }, [props.initialUser])

  const cancelChanges = () => {
    const { onDismiss } = props
    if (onDismiss !== undefined) {
      onDismiss(null, 'cancel')
    }
  }

  function handleCreateUser(user: UserAddInputDTO) {
    createUser.mutate(user, {
      onSuccess() {
        successToast(labelCreateUserSuccess)
        props.onDismiss(null, role)
      },
      onError(err) {
        if (err instanceof AxiosError) {
          err.response?.data.errors[0] === undefined
            ? // TODO missing translations
              warningToast(err.response?.data.errors.Email[0])
            : warningToast(err.response?.data.errors[0])
        } else {
          dangerToast(labelSomethingWentWrong)
        }
      }
    })
  }

  function handleUpdateUser(user: UserUpdateInputDTO & { id: number }) {
    updateUser.mutate(user, {
      onSuccess() {
        successToast(labelSuccessToUpdateProfile)
        props.onDismiss(null, 'confirm')
      },
      onError() {
        dangerToast(labelFailToUpdateProfile)
      }
    })
  }

  async function handleUpdateProfile(user: UserUpdateInputDTO & { id: number }) {
    updateUser.mutate(user, {
      onSuccess: async () => {
        successToast(labelSuccessToUpdateProfile)
        await client.invalidateQueries(['user'])
        props.onDismiss(null, 'confirm')
      },
      onError() {
        dangerToast(labelFailToUpdateProfile)
      }
    })
  }

  const editProfileDataIsValid =
    props.title === TitleEnum.EditProfile && !!firstName && !!lastName && email !== undefined

  const editUserDataIsValid =
    props.title === TitleEnum.EditUser && !!firstName && !!lastName && !!email && role !== undefined

  const confirmChanges = () => {
    if (props.title === TitleEnum.CreateUser && !isDisabled()) {
      return handleCreateUser({
        ...(role === UserRole.Admin ? {} : { companyId: option?.id }),
        username: username!,
        firstName: firstName!,
        lastName: lastName!,
        email: email!,
        role,
        preferredLanguage: preferredLanguage!
      })
    }
    if (editProfileDataIsValid) {
      const user = {
        firstName,
        lastName,
        email,
        role: loggedUser.data?.role!,
        id: loggedUser.data?.id!,
        preferredLanguage: loggedUser.data?.preferredLanguage!
      }
      return handleUpdateProfile(user)
    }
    if (editUserDataIsValid && !!props.initialUser?.id) {
      const user = {
        firstName,
        lastName,
        email,
        role,
        id: Number(props.initialUser.id),
        preferredLanguage: props.initialUser.preferredLanguage!
      }
      handleUpdateUser(user)
    }
  }

  let filteredCompanies =
    searchQuery.length > 0
      ? props.companies?.filter(company =>
          company.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : ([] as Company[])

  const handleSetOption = (company: Company) => {
    setOption(company)
    setSearchQuery(`${company.name} ${company.address}`)
    filteredCompanies = []
  }

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <StyledIonTitle>{title}</StyledIonTitle>
        </IonToolbar>

        {isCreateUserModal && (
          <IonSegment
            style={{ maxWidth: 200, margin: '0 15px 15px 15px' }}
            mode="ios"
            value={role}
            onIonChange={e => setRole(e.detail.value!)}
          >
            <IonSegmentButton value={UserRole.Employee} color="primary">
              <IonLabel>{labelRoleOptionEmployee}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={UserRole.Admin} color="primary">
              <IonLabel>{labelRoleOptionAdmin}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        )}

        {isCreateUserModal && role === UserRole.Employee && (
          <IonToolbar>
            <IonSearchbar
              debounce={500}
              mode="ios"
              value={searchQuery}
              onIonChange={e => setSearchQuery(e.detail.value!)}
              placeholder={searchPlaceholder}
              onIonClear={() => setOption(undefined)}
            ></IonSearchbar>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="ion-no-padding ion-no-margin">
        {searchQuery.trim().length > 0 && filteredCompanies && filteredCompanies?.length > 0 && (
          <ListCompanies companies={filteredCompanies} setOption={handleSetOption} />
        )}
        <StyledIonList inset={true}>
          {isCreateUserModal && (
            <>
              <IonItem>
                <Label style={{ fontFamily: 'Poppins' }} position="floating">
                  {labelUserName}
                </Label>
                <Input
                  required
                  type="text"
                  value={username}
                  onIonChange={e => setUserName(e.detail.value!)}
                />
              </IonItem>
              <IonItem>
                <Label position="floating">{labelEmail}</Label>
                <Input type="email" value={email} onIonChange={e => setEmail(e.detail.value!)} />
              </IonItem>
              <IonItem style={{ fontFamily: 'Poppins' }}>
                <Label position="floating">{labelLanguage}</Label>
                <Select interface="popover" onIonChange={e => setPreferredLanguage(e.detail.value)}>
                  <Option value={'German'}>{labelLanguageOptionGerman}</Option>
                  <Option value={'English'}>{labelLanguageOptionEnglish}</Option>
                </Select>
              </IonItem>
            </>
          )}
          {props.title === TitleEnum.EditUser && (
            <IonItem>
              <Label position="floating">{labelEmail}</Label>
              <Input type="email" value={email} onIonChange={e => setEmail(e.detail.value!)} />
            </IonItem>
          )}
          <IonItem>
            <Label position="floating">{labelFirstName}</Label>
            <Input type="text" value={firstName} onIonChange={e => setFirstName(e.detail.value!)} />
          </IonItem>
          <IonItem>
            <Label position="floating">{labelLastName}</Label>
            <Input type="text" value={lastName} onIonChange={e => setLastName(e.detail.value!)} />
          </IonItem>
          {isEditProfileModal ? (
            <>
              <IonItem>
                <Label position="floating">{labelEmail}</Label>
                <Input type="text" value={email} onIonChange={e => setEmail(e.detail.value!)} />
              </IonItem>
              {loggedUser.data?.company && (
                <IonItem>
                  <Label position="floating">{labelOrganisation}</Label>
                  <Input readonly={true} type="text" value={loggedUser.data?.company} />
                </IonItem>
              )}
            </>
          ) : null}
        </StyledIonList>
      </IonContent>
      <IonList inset={true}>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              onClick={cancelChanges}
              class="custom-button"
              style={{
                borderRadius: '5px',
                border: '1px solid black'
              }}
            >
              {labelCancel}
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton
              class="custom-button"
              style={{
                backgroundColor: '#2196f3',
                color: 'white',
                borderRadius: '5px'
              }}
              disabled={isDisabled() || createUser.isLoading}
              onClick={confirmChanges}
            >
              {labelDone}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonList>
    </IonPage>
  )
}
export default CreateOrEditUserModalContent

type ListCompaniesProps = {
  companies: Company[]
  setOption: any
}

const ListCompanies = (props: ListCompaniesProps) => {
  return (
    <IonList lines="full">
      {props.companies.slice(0, 10).map(c => (
        <IonItem
          style={{ cursor: 'pointer' }}
          onClick={() => props.setOption(c)}
          key={c.id}
        >{`${c.name}, ${c.address}`}</IonItem>
      ))}
    </IonList>
  )
}
