import { useTranslation } from 'react-i18next'
import { Title, SubTitle } from '../../ui/Title'
import { IonIcon, IonItem, IonPage } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'

export default function ErrorPage() {
  const { t } = useTranslation()
  const title = t('error-page.title')
  const content = t('error-page.content')
  const back = t('auth.back-to-login')

  return (
    <IonPage className="ion-padding">
      <div>
        <Title>{title}!</Title>
        <SubTitle>{content}</SubTitle>
        <IonItem
          style={{ cursor: 'pointer', display: 'inline-block' }}
          lines="none"
          onClick={() => {
            window.location.href = '/'
          }}
        >
          <IonIcon slot="start" size="small" icon={arrowBackOutline} />
          <p>{back}</p>
        </IonItem>
      </div>
    </IonPage>
  )
}
