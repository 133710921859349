import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react'
import {
  OrdersInCountryDTO,
  OrderWithItemCountDTO,
  OrderReportOutputDTO,
  OrderStatisticsOutputDTO,
  OrdersInSalesChannelDTO
} from '../types/orders'
import useOrderStatistics from '../hooks/queries/useOrderStatistics'
import { UseQueryResult } from '@tanstack/react-query'

export const TODAY = 't'
export const WEEK = 'w'
export const MONTH = 'm'
export const YEAR = 'y'

type Today = typeof TODAY
type Week = typeof WEEK
type Month = typeof MONTH
type Year = typeof YEAR

export type Periods = Today | Week | Month | Year

export type OrderStatistics = {
  ordersPerCountry: OrdersInCountryDTO[]
  ordersWithItemCount: OrderWithItemCountDTO[]
  totalOrders: number
  processedOrders: number
  ordersWithoutInventory: number
  avgNoOfArticlesPerOrder: number
  orderReports: OrderReportOutputDTO[]
  ordersPerSalesChannel: OrdersInSalesChannelDTO[]
}

type OrderStatisticsContextType = {
  orderStatistics: OrderStatistics
  period: Periods
  setPeriod: Dispatch<SetStateAction<Periods>>
}

const OrderStatisticsContext = createContext({} as OrderStatisticsContextType)

export function useOrderStatisticsContext() {
  return useContext(OrderStatisticsContext)
}

export function OrderStatisticsProvider(props: PropsWithChildren) {
  const [period, setPeriod] = useState<Periods>('w')
  const orderStatisticsQuery = useOrderStatistics(period)

  const getOrderStatistics = (
    statistics: UseQueryResult<OrderStatisticsOutputDTO, unknown>
  ): OrderStatistics => {
    return {
      ordersPerCountry: statistics.data?.ordersPerCountry ?? [],
      ordersPerSalesChannel: statistics.data?.ordersPerSalesChannel ?? [],
      ordersWithItemCount: statistics.data?.ordersWithItemCount ?? [],
      totalOrders: statistics.data?.totalOrders ?? 0,
      ordersWithoutInventory: statistics.data?.ordersWithoutInventory ?? 0,
      avgNoOfArticlesPerOrder: statistics.data?.avgNoOfArticlesPerOrder ?? 0,
      orderReports: statistics.data?.orderReports ?? [],
      processedOrders: statistics.data?.processedOrders ?? 0
    }
  }

  return (
    <OrderStatisticsContext.Provider
      value={{
        orderStatistics: getOrderStatistics(orderStatisticsQuery),
        period,
        setPeriod
      }}
    >
      {props.children}
    </OrderStatisticsContext.Provider>
  )
}
