import { useQuery } from '@tanstack/react-query'
import { Users } from '../../api'
import { useAuthContext } from '../../context/AuthContext'
import useDangerToast from '../toasts/useDangerToast'
import { useTranslation } from 'react-i18next'

export default function useUser(id: number | undefined) {
  const { isCurrentUserAdmin } = useAuthContext()
  const presentToast = useDangerToast()
  const { t } = useTranslation()
  const errorMessage: string = t('common.fetchErrorMessage')

  return useQuery(
    ['user', id],
    async () => {
      return await Users.getUserById(id!)
    },
    {
      enabled: id !== undefined || isCurrentUserAdmin,
      onError: error => {
        console.error('Error:', error)
        presentToast(errorMessage, 5000)
      }
    }
  )
}
