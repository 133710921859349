export interface IconI {
  width: number
  height: number
  active?: boolean
  marginRight?: string
}

export const DashboardIcon = ({ width, height, active, marginRight }: IconI) => (
  <div style={{ marginRight, width, height }}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H8V18ZM10 18H16C17.1 18 18 17.1 18 16V9H10V18ZM18 7V2C18 0.9 17.1 0 16 0H10V7H18Z"
        fill={active ? '#0290EE' : '#646465'}
      />
    </svg>
  </div>
)

export const OrderOverviewIcon = ({ width, height, active, marginRight }: IconI) => (
  <div style={{ marginRight, width, height }}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3333 0H8.66667C7.5625 0 6.66667 0.895833 6.66667 2V6.66667C6.66667 8.1375 7.8625 9.33333 9.33333 9.33333H14.6667C16.1375 9.33333 17.3333 8.1375 17.3333 6.66667V2C17.3333 0.895833 16.4375 0 15.3333 0H13.6667V3.33333C13.6667 3.7 13.3667 4 13 4H11C10.6333 4 10.3333 3.7 10.3333 3.33333V0ZM2.66667 10.6667C1.19583 10.6667 0 11.8625 0 13.3333V18.6667C0 20.1375 1.19583 21.3333 2.66667 21.3333H9.33333C10.8042 21.3333 12 20.1375 12 18.6667V13.3333C12 11.8625 10.8042 10.6667 9.33333 10.6667H7.66667V14C7.66667 14.3667 7.36667 14.6667 7 14.6667H5C4.63333 14.6667 4.33333 14.3667 4.33333 14V10.6667H2.66667ZM14.6667 21.3333H21.3333C22.8042 21.3333 24 20.1375 24 18.6667V13.3333C24 11.8625 22.8042 10.6667 21.3333 10.6667H19.6667V14C19.6667 14.3667 19.3667 14.6667 19 14.6667H17C16.6333 14.6667 16.3333 14.3667 16.3333 14V10.6667H14.6667C14.0417 10.6667 13.4667 10.8792 13.0125 11.2417C13.2167 11.675 13.3333 12.1583 13.3333 12.6667V19.3333C13.3333 19.8417 13.2167 20.325 13.0125 20.7583C13.4667 21.1208 14.0417 21.3333 14.6667 21.3333Z"
        fill={active ? '#0290EE' : '#646465'}
      />
    </svg>
  </div>
)

export const InventoryIcon = ({ width, height, active, marginRight }: IconI) => (
  <div style={{ marginRight, width, height }}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.33333C0 0.595833 0.595833 0 1.33333 0H4.37083C5.51667 0 6.5375 0.733333 6.9 1.825L10.7375 13.3333C11.9917 13.3542 13.1042 13.9542 13.8208 14.875L22.2417 12.0667C22.9417 11.8333 23.6958 12.2125 23.9292 12.9083C24.1625 13.6042 23.7833 14.3625 23.0875 14.5958L14.6667 17.4042C14.6292 19.5792 12.8542 21.3333 10.6667 21.3333C8.45833 21.3333 6.66667 19.5417 6.66667 17.3333C6.66667 16.05 7.27083 14.9083 8.20833 14.175L4.37083 2.66667H1.33333C0.595833 2.66667 0 2.07083 0 1.33333ZM10.2 5.60417C9.97083 4.90417 10.3542 4.15 11.0542 3.925L12.9583 3.30833L13.7833 5.84583L16.3208 5.02083L15.4917 2.48333L17.3958 1.86667C18.0958 1.6375 18.85 2.02083 19.075 2.72083L21.1333 9.0625C21.3625 9.7625 20.9792 10.5167 20.2792 10.7417L13.9375 12.8C13.2375 13.0292 12.4833 12.6458 12.2583 11.9458L10.2 5.60417Z"
        fill={active ? '#0290EE' : '#646465'}
      />
    </svg>
  </div>
)

export const DocumentsIcon = ({ width, height, active, marginRight }: IconI) => (
  <div style={{ marginRight, width, height }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={width} height={height}>
      <path
        fill={active ? '#0290EE' : '#646465'}
        d="M428 224H288a48 48 0 01-48-48V36a4 4 0 00-4-4h-92a64 64 0 00-64 64v320a64 64 0 0064 64h224a64 64 0 0064-64V228a4 4 0 00-4-4z"
      />
      <path
        fill={active ? '#0290EE' : '#646465'}
        d="M419.22 188.59L275.41 44.78a2 2 0 00-3.41 1.41V176a16 16 0 0016 16h129.81a2 2 0 001.41-3.41z"
      />
    </svg>
  </div>
)

export const SignOutIcon = ({ width, height, active, marginRight }: IconI) => (
  <div style={{ marginRight, width, height }}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5605 11.5599C24.1465 10.9739 24.1465 10.0223 23.5605 9.43637L17.5602 3.43609C16.9743 2.85013 16.0227 2.85013 15.4367 3.43609C14.8507 4.02206 14.8507 4.97367 15.4367 5.55963L18.8775 9.00041H9.00044C8.17071 9.00041 7.50037 9.67076 7.50037 10.5005C7.50037 11.3302 8.17071 12.0005 9.00044 12.0005H18.8775L15.4367 15.4413C14.8507 16.0273 14.8507 16.9789 15.4367 17.5649C16.0227 18.1508 16.9743 18.1508 17.5602 17.5649L23.5605 11.5646V11.5599ZM7.50037 3.00014C8.33009 3.00014 9.00044 2.32979 9.00044 1.50007C9.00044 0.670343 8.33009 0 7.50037 0H4.50022C2.01572 0 0 2.01572 0 4.50021V16.5008C0 18.9852 2.01572 21.001 4.50022 21.001H7.50037C8.33009 21.001 9.00044 20.3306 9.00044 19.5009C9.00044 18.6712 8.33009 18.0008 7.50037 18.0008H4.50022C3.67049 18.0008 3.00015 17.3305 3.00015 16.5008V4.50021C3.00015 3.67048 3.67049 3.00014 4.50022 3.00014H7.50037Z"
        fill={active ? '#0290EE' : '#646465'}
      />
    </svg>
  </div>
)
