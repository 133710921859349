export enum UserRole {
  Employee = 'Employee',
  Admin = 'Admin'
}

export const UserRoleDisplay = {
  [UserRole.Employee]: 'Employee',
  [UserRole.Admin]: 'Admin'
}

export type CurrentUserType = {
  companyId: string
  email: string
  exp: number
  iat: number
  nameid: string
  nbf: number
  role: UserRole
  unique_name: string
}

export type UserAddInputDTO = {
  firstName: string
  lastName: string
  role: string
  preferredLanguage: string
  username: string
  email: string
  companyId?: number
}

export type UserOutputDTO = {
  id: number
  firstName?: string
  lastName?: string
  role?: string
  preferredLanguage?: string
  username?: string
  email?: string
  company?: string
}

export type User = {
  id: number
  username: string
  email: string
  firstName: string
  lastName: string
  role: string
  company: string
}

export type UserUpdateInputDTO = {
  firstName: string
  lastName: string
  email: string
  preferredLanguage: string
}

export type UserPasswordSetInputDTO = {
  password: string
  confirmPassword: string
}

export type UserWithId = UserUpdateInputDTO & {
  id: number
}

export type UserChangePasswordInputDTO = {
  oldPassword: string
  password: string
  confirmPassword: string
}
