interface InventoryIconI {
  width: string
  height: string
}

const InventoryIcon: React.FC<InventoryIconI> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.2384 26.3918V32.9438C31.2384 38.4158 29.0544 40.5998 23.5824 40.5998H17.0544C11.6064 40.5998 9.39844 38.4158 9.39844 32.9438V26.3918C9.39844 20.9438 11.5824 18.7598 17.0544 18.7598H23.6064C29.0544 18.7598 31.2384 20.9438 31.2384 26.3918Z"
      stroke="#742445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5978 17.0324V23.5844C40.5978 29.0564 38.4138 31.2404 32.9418 31.2404H31.2378V26.3924C31.2378 20.9444 29.0538 18.7604 23.5818 18.7604H18.7578V17.0324C18.7578 11.5604 20.9418 9.40039 26.4138 9.40039H32.9658C38.4138 9.40039 40.5978 11.5844 40.5978 17.0324Z"
      stroke="#742445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.0031 32.1992C49.0031 41.4872 41.4911 48.9992 32.2031 48.9992L34.7231 44.7992"
      stroke="#742445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 17.8C1 8.512 8.512 1 17.8 1L15.28 5.2"
      stroke="#742445"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  // </div>
)

export default InventoryIcon
