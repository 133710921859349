import axios, { type AxiosRequestConfig } from "axios";
import Config from "../config";
import type {
	ApiResponse,
	ResetPasswordRequest,
	UpdateUserRequest,
	UpdateUserResponse,
	ChangePasswordRequest,
	OrderResponse,
	OrderStatisticsResponse,
	CompanyResponse,
	DeliveryNoteResponse,
	InvoiceResponse,
	ProductResponse,
} from "../types/api";
import type {
	ChangePasswordData,
	LoginInputDTO,
	LoginOutputDTO,
	ResetPasswordData,
} from "../types/auth";
import type {
	UserAddInputDTO,
	UserOutputDTO,
	UserWithId,
} from "../types/users";
import { UNAUTHORIZED } from "../utils/api-statuses";
import { appQueryClient } from "..";
import { BlobInfo } from "../utils/blob-info";
import type { DownloadInvoiceOutputDTO } from "../types/invoices";
import type { DownloadDeliveryNodeOutputDTO } from "../types/delivery";

const axiosInstance = axios.create({
	baseURL: Config.API_URL,
});

axiosInstance.interceptors.request.use((config: any) => {
	const token = localStorage.getItem("token");
	const tokenExp = localStorage.getItem("tokenExp");
	if (tokenExp && new Date(+tokenExp * 1000) < new Date()) {
		localStorage.removeItem("token");
	}
	if (token) {
		config.headers = {
			...config.headers,
			Authorization: token,
		};
	}

	return config;
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},

	(error) => {
		if (
			error?.response?.data === "" &&
			error?.response?.status === UNAUTHORIZED
		) {
			localStorage.removeItem("token");
			appQueryClient.cancelQueries();
			appQueryClient.clear();
			localStorage.removeItem("token");
			window.location.href = "/login";
		}
		throw error;
	},
);

const requests = {
	// get: <P>(url: string) => axiosInstance.get<P>(url).then((res) => res.data),
	get: <P>(url: string, params?: any) => {
		return axiosInstance.get<P>(url, { params }).then((res) => res.data);
	},
	post: <P, D>(
		url: string,
		data: D,
		file?: boolean,
		config: AxiosRequestConfig = {},
	) => {
		if (file) {
			config.headers = {
				...config.headers,
				"Content-Type": "multipart/form-data",
			};
		}

		return axiosInstance.post<P>(url, data, config).then((res) => res.data);
	},
	put: <P, D>(url: string, data: D) =>
		axiosInstance.put<ApiResponse<P>>(url, data).then((res) => res.data),
	patch: <P, D>(url: string, data: D) =>
		axiosInstance.patch<P>(url, data).then((res) => res.data),
	delete: <P>(url: string) =>
		axiosInstance.delete<ApiResponse<P>>(url).then((res) => res.data),
	getBlob: (url: string, data: any) =>
		axiosInstance
			.post<Blob>(url, data, { responseType: "blob" })
			.then((res) => {
				const contentLength = res.headers["content-length"];
				return new BlobInfo(res.data, contentLength);
			}),
};

// TODO remove any with response type
export const Auth = {
	login: (data: LoginInputDTO) =>
		requests.post<LoginOutputDTO, LoginInputDTO>("/User/Login", data),
	acquirePasswordReset: (userId: number) =>
		requests.get<any>(`/User/AcquirePasswordReset/${userId}`),
	resetPassword: (data: ResetPasswordData) =>
		requests.post<any, ResetPasswordRequest>(
			`/User/ResetPassword${data.queryString}`,
			{ password: data.password, confirmPassword: data.confirmPassword },
		),
	forgotPassword: (email: string) =>
		requests.get<any>(`/User/ForgotPassword/${email}`),
	changePassword: (data: ChangePasswordData) =>
		requests.post<any, ChangePasswordRequest>("/User/ChangePassword", {
			oldPassword: data.oldPassword,
			password: data.password,
			confirmPassword: data.confirmPassword,
		}),
};

export const Users = {
	// TODO remove any with response type
	getUsers: () => requests.get<UserOutputDTO[]>("/User"),
	createUser: (data: UserAddInputDTO) =>
		requests.post<UserOutputDTO, UserAddInputDTO>("/User", data),
	getUserById: (userId: number) =>
		requests.get<UserOutputDTO>(`/User/${userId}`),
	updateUser: (user: UserWithId) => {
		const { id, ...data } = user;
		return requests.patch<UpdateUserResponse, UpdateUserRequest>(
			`/User/${id}`,
			data,
		);
	},
	deleteUser: (userId: number) => requests.delete<any>(`/User/${userId}`),
	activateUser: ({ token, email }: { token: string; email: string }) =>
		requests.get<any>(`/User/Activate?token=${token}&email=${email}`),
};

export const Orders = {
	getOrders: () => requests.get<OrderResponse>("/Order"),
	getStatistics: (period: string) =>
		requests.get<OrderStatisticsResponse>(`/Order/Statistics/${period}`),
};

export const FilteredOrders = {
	getFilteredOrders: (filters: any) => {
		return requests.post("/Order/filtered", filters);
	},
};

export const FilteredOrdersCount = {
	getFilteredOrdersCount: (filters: any) => {
		return requests.post("/Order/filtered/count", filters);
	},
};

export const Companies = {
	getCompanies: () => requests.get<CompanyResponse>("/Company"),
	syncCompanies: () => requests.get<any>("/Company/Sync"),
};

export const DeliveryNotes = {
	getDeliveryNotes: () => requests.get<DeliveryNoteResponse>("/DeliveryNote"),
	getDeliveryNotePdf: (data: DownloadDeliveryNodeOutputDTO) =>
		requests.getBlob("/DeliveryNote/download", data),
};

export const Invoices = {
	getInvoices: () => requests.get<InvoiceResponse>("/Invoice"),
	getInvoicePdf: (data: DownloadInvoiceOutputDTO) =>
		requests.getBlob("/Invoice/download", data),
};

export const Products = {
	getProducts: () => requests.get<ProductResponse>("/Product"),
};
