import { useIonToast } from '@ionic/react'

const useSuccessToast = () => {
  const [presentToast] = useIonToast()

  return (message?: string, duration?: number) => {
    return presentToast({
      message: message ?? 'Success!',
      duration: duration ?? 2000,
      position: 'top',
      color: 'success'
    })
  }
}
export default useSuccessToast
