import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { Users } from '../../api'
import { UserWithId } from '../../types/users'
import useDangerToast from '../toasts/useDangerToast'
import { useTranslation } from 'react-i18next'

export default function useUpdateUser() {
  const presentToast = useDangerToast()
  const { t } = useTranslation()
  const errorMessage: string = t('common.fetchErrorMessage')

  const queryClient = useQueryClient()
  return useMutation((user: UserWithId) => Users.updateUser(user), {
    onSuccess: () => {
      queryClient.invalidateQueries(['users'])
    },
    onError: error => {
      console.error('Error:', error)
      presentToast(errorMessage, 5000)
    }
  })
}
