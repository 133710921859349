import { Orders } from '../../api'
import { useQuery } from '@tanstack/react-query'
import { useAuthContext } from '../../context/AuthContext'
import useDangerToast from '../toasts/useDangerToast'
import { useTranslation } from 'react-i18next'

export default function useOrderStatistics(period: string) {
  const { isCurrentUserEmployee } = useAuthContext()
  const presentToast = useDangerToast()
  const { t } = useTranslation()
  const errorMessage: string = t('common.fetchErrorMessage')

  return useQuery(
    ['order-statistics', period],
    async () => {
      return await Orders.getStatistics(period)
    },
    {
      enabled: isCurrentUserEmployee,
      onError: error => {
        console.error('Error:', error)
        presentToast(errorMessage, 5000)
      }
    }
  )
}
