export const UPPERCASE = /[A-Z]/
export const LOWERCASE = /[a-z]/
export const DIGIT = /\d/
export const SYMBOL = /[!@#$%^&*(),.?":{}[\]+=|<>,';\-\`_~\\]/
export const MIN_LENGTH = 9

export function testUppercase(value: string): boolean {
  return UPPERCASE.test(value)
}

export function testLowercase(value: string): boolean {
  return LOWERCASE.test(value)
}

export function testDigit(value: string): boolean {
  return DIGIT.test(value)
}

export function testSymbol(value: string): boolean {
  return SYMBOL.test(value)
}

export function testMinLength(value: string): boolean {
  return value.trim().length >= MIN_LENGTH
}
