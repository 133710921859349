interface TotalOrderIconI {
  width: string
  height: string
}

const TotalOrderIcon: React.FC<TotalOrderIconI> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.3125 20.881L41.3525 29.241C39.6725 36.461 36.3525 39.381 30.1125 38.781C29.1125 38.701 28.0325 38.521 26.8725 38.241L23.5125 37.441C15.1725 35.461 12.5925 31.341 14.5525 22.981L16.5125 14.601C16.9125 12.901 17.3925 11.421 17.9925 10.201C20.3325 5.36099 24.3125 4.06099 30.9925 5.64099L34.3325 6.42099C42.7125 8.38099 45.2725 12.521 43.3125 20.881Z"
      stroke="#4c8dff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1168 38.7792C28.8768 39.6192 27.3168 40.3192 25.4168 40.9392L22.2568 41.9792C14.3168 44.5392 10.1368 42.3992 7.55678 34.4592L4.99678 26.5592C2.43678 18.6192 4.55678 14.4192 12.4968 11.8592L15.6568 10.8192C16.4768 10.5592 17.2568 10.3392 17.9968 10.1992C17.3968 11.4192 16.9168 12.8992 16.5168 14.5992L14.5568 22.9792C12.5968 31.3392 15.1768 35.4592 23.5168 37.4392L26.8768 38.2392C28.0368 38.5192 29.1168 38.6992 30.1168 38.7792Z"
      stroke="#4c8dff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2812 17.0586L34.9812 19.5186"
      stroke="#4c8dff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3047 24.8008L29.1047 26.2808"
      stroke="#4c8dff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  // </div>
)

export default TotalOrderIcon
