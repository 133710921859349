import styled from 'styled-components'
import { IonInput } from '@ionic/react'

export const Input = styled(IonInput)`
  input {
    background-color: transparent !important;
    font-family: Poppins !important;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-ms-input-placeholder {
    background-color: transparent !important;
  }
`
